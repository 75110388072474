//to compile node_modules/.bin/webpack
import * as sbjs from "sourcebuster";
import "navigator.sendbeacon";

interface RrpCaptureParams {
  disposition: string;
  type: string;
  subject: string;
}

declare const rrp_settings: {
  url: string;
  key: string;
  chat_api_urls: Array<RequestInfo>;
  fetch_campaigns_url: RequestInfo;
};

interface IFrameXMLHttpRequest extends XMLHttpRequest {
  prototype: {
    open: Function;
    readystatechange: Function;
  };
}
interface Map {
  [key: string]: string | undefined;
}
declare global {
  interface Window {
    rrpCapture: Function;
    XMLHttpRequest: IFrameXMLHttpRequest;
    jQuery: any;
    $: any;
  }
}
//Turn on sourcebuster
sbjs.init();

async function postJsonData(url: string, data: object) {
  try {
    let response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const ret = await response.json();
    return ret;
  } catch (error) {
    console.error("Error:", error);
  }
}

/*
Disposition: (lead or nolead)
Type: (form, mailto, chat, offsite, etc.)
Detail: (free text, whatever max characters you think appropriate, optional)
*/
window.rrpCapture = (rrpCaptureParams: RrpCaptureParams): boolean => {
  //event.preventDefault();
  if (!rrp_settings || typeof rrp_settings !== "object") {
    throw new Error("rrp_settings must be defined");
  }
  if (!("url" in rrp_settings)) {
    throw new Error("rrp_settings.url must be defined");
  }
  if (!("key" in rrp_settings)) {
    throw new Error("rrp_settings.key must be defined");
  }

  let data = {
    cookies: document.cookie.split(";").reduce(
      (ac, str) =>
        Object.assign(ac, {
          [str.split("=")[0].trim()]: decodeURIComponent(str.split("=")[1]),
        }),
      {}
    ),
    values: rrpCaptureParams,
  };
  let url = new URL(
    `${rrp_settings.url}/api/${rrp_settings.key}/capture`.replace("//", "/")
  );
  navigator.sendBeacon(url.toString(), JSON.stringify(data));
  return true;
};
function waitForElement(
  selector: string
): Promise<HTMLIFrameElement | Element> {
  return new Promise(function (resolve, reject) {
    let element = document.querySelector(selector);

    if (element) {
      resolve(element);
      return;
    }

    let observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === "attributes") {
          let element = document.querySelector(selector);

          if (element) {
            observer.disconnect();
            resolve(<HTMLIFrameElement>element);
            return;
          }
        }
        [].forEach.call(mutation.addedNodes, function (node: Element) {
          if (node.matches && node.matches(selector)) {
            observer.disconnect();
            resolve(<HTMLIFrameElement>node);
            return;
          }
        });
      });
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  });
}

waitForElement("[data-chat-id]").then(function (element: HTMLIFrameElement) {
  const cookies: Map = document.cookie.split(";").reduce(
    (ac, str) =>
      Object.assign(ac, {
        [str.split("=")[0].trim()]: decodeURIComponent(str.split("=")[1]),
      }),
    {}
  );
  const filtered = Object.keys(cookies)
    .filter((key) => key.match(/^sbjs/i))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: cookies[key],
      };
    }, {});

  Promise.all(
    rrp_settings.chat_api_urls.map((u) =>
      postJsonData(u.toString(), {
        chat_id: element.getAttribute("data-chat-id"),
        json: JSON.stringify({
          cookies: filtered,
        }),
      })
    )
  ).then((responses) => Promise.all(responses.map((res) => res)));
});

document.addEventListener("DOMContentLoaded", function () {
  let url_params = new URLSearchParams(window.location.search);
  let url = new URL(rrp_settings.fetch_campaigns_url.toString());
  if (url_params.has("rrpclear")) {
    url.searchParams.append("rrpclear", "1");
  }

  fetch(url.toString() as RequestInfo, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
});
